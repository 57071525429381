import config from '~/utils/config'
import queryString from 'query-string'

const state = () => ({
  figures: [],
})


const mutations = {
  SET_FIGURES(sate, payload) {
    sate.figures = payload
  }
}

const getters = {
  figures(state) {
    return state.figures
  }
}

const actions = {
   getFigures({commit}) {
    return new Promise(async (resolve, reject) => {
      try {
        const {data: {data}} = await this.$axios({
          url: `${config.api.figure}`,
          method: 'get'
        })
        commit('SET_FIGURES', data)
        resolve(data)
      } catch (err) {
        resolve([])
      }
    })
  }
}

export default {
  state, mutations, actions, getters
}
