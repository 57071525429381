export const ACTION_GET_CAR_HOME = 'cars/getCarsHome'
export const ACTION_GET_CAR_LIST = 'cars/getCarList'

/* Banner */
export const ACTION_GET_BANNER = 'banner/getBanners'
export const ACTION_GET_BANNER_LIST_CAR = 'banner/getBannersListCars'
export const ACTION_GET_BANNER_CUSTOM = 'banner/getBannersCustom'
/* end Banner */

// Partner
export const ACTION_GET_PARTNER = 'partner/getPartners'

// News
export const ACTION_GET_NEWS = 'news/getNews'
export const ACTION_GET_NEW_ITEM = 'news/getNewItem'
export const ACTION_GET_CATEGORY_NEWS = 'news/getCategoryNews'
export const ACTION_GET_MOST_VIEWS_NEWS = 'news/getMostViewedNews'
export const ACTION_GET_HOT_NEWS = 'news/getHotNews'
export const ACTION_GET_ALL_NEW_BUY_CATEGORIES = 'news/getAllNewsBuyCategories'
export const ACTION_SEARCH_NEWS = 'news/searchNews'
export const ACTION_SET_CURRENT_CATEGORY= 'news/setcurrentCategory'
export const SET_CURRENT_ID_NEW= 'news/currentIDNew'
export const ACTION_GET_RELATED_NEWS = 'news/getRelatedNews'

//Manufacturer
export const ACTION_GET_MANUFACTURERS = 'manufacturers/getManufactures'
export const ACTION_GET_MANUFACTURER_INFO = 'manufacturers/getManufacturerInfo'
export const ACTION_GET_BRAND_INFO = 'manufacturers/getBrandInfo'

// Car details
export const ACTION_SET_CURRENT_CAR = 'car-details/setCurrentCar'
export const ACTION_GET_CAR_DETAILS = 'car-details/getCarDetails'
export const ACTION_GET_CARS_BY_IDS = 'car-details/getCarsByIds'

//Rate
export const ACTION_GET_RATES = 'rates/getRates'

//Statistic

export const ACTION_GET_STATISTICS = 'statistics/getStatistics'

//Recruit
export const ACTION_GET_RECRUIT = 'recruit/getRecruit'
export const ACTION_GET_CATEGRORY = 'recruit/getCateRecruit'
export const ACTION_GET_RECRUIT_DETAIL = 'recruit/getRecruitDetail'
export const ACTION_GET_RECRUIT_RANDOM = 'recruit/getRecruitRandom'
export const ACTION_SET_JOB = 'recruit/setJob'

// cars
export const ACTION_SET_FOVORITE_CARS = 'cars/setFavoriteCars'
export const ACTION_GET_FOVORITE_CARS = 'cars/getFavoriteCars'
export const ACTION_ADD_FOVORITE_CAR = 'cars/addFavoriteCar'
export const ACTION_REMOVE_FOVORITE_CAR = 'cars/removeFavoriteCar'
export const ACTION_GET_LEAD = 'cars/getLead'
export const ACTION_GET_CAR_ATTRIBUTES = 'cars/getCarAttributes'
export const ACTION_GET_SEARCH_HISTORY = 'cars/getSearchHistory'
export const ACTION_GET_SUGGEST_SEARCH = 'cars/getSuggestSearch'
export const ACTION_GET_DELETE_ALL_SEARCH_HISTORY = 'cars/deleteAllSearchHistory'

// Buy car
export const ACTION_BUY_CAR = 'cars/buyCar'

//Contact
export const ACTION_ADD_CONTACT = 'contact/addContact'

//search
export const ACTION_SEARCH_CAR_BY_NAME = 'cars/searchCarByName'
export const ATION_GET_CAR_FILTERED = 'cars/getCarsFiltered'
export const ATION_GET_CAR_MENUFACTURER_IDS = 'cars/getCarsByMenufacturerIds'
export const ATION_FILTER_CAR = 'cars/filterCar'

//auth
export const ACTION_CHECK_EXISTENCE = 'authen/checkExistence'
export const ACTION_LOGIN = 'authen/login'
export const ACTION_LOGOUT = 'authen/logout'
export const ACTION_CHANGE_LOGIN_STATUS = 'authen/changeLoginStatus'
export const ACTION_REQUEST_OTP = 'authen/requestOTP'
export const ACTION_VALIDATE_OTP = 'authen/validateOTP'
export const ACTION_REGISTER_ACCOUNT = 'authen/register'
export const ACTION_SIGN_IN_WITH_GOOGLE = 'authen/signInWithGoogle'
export const ACTION_SIGN_IN_WITH_VETC = 'authen/signInWithVETC'
export const ACTION_SIGN_IN_WITH_FACEBOOK = 'authen/signInWithFacebook'
export const ACTION_LOGIN_SSO = 'authen/loginSSO'
export const ACTION_LOGIN_FIREBASE = 'authen/signInWithFirebase'
export const ACTION_GET_OAUTH = 'authen/getOauth'
export const ACTION_GET_OAUTH_CLIENT = 'authen/getOauthClient'

//user
export const ACTION_UPDATE_USERINFO = 'authen/updateUserInfo'
export const ACTION_SET_TOKEN = 'authen/setToken'

//banks
export const ACTION_GET_INSTALLMENT_POLICIES = 'installmentpolicies/getInstallmentPolicies'

//sellCar
export const ACTION_ADD_INFOR_SELLCAR = 'cars/addInforSellCar'

//figures
export const ACTION_GET_FIGURES = 'figures/getFigures'

//rsa
export const ACTION_ADD_FORM_RSA = 'rsa/addRSAForm'

//faq
export const ACTION_RAISE_FAQ = 'faq/raiseQuestion'
export const ACTION_GET_FAQ = 'faq/getQuestion'

//upload file
export const ACTION_UP_LOAD_FILE = 'upload-files/uploadFiles'

//application
export const ACTION_SEND_APPLICATION = 'application/sendApplication'

//Sale Policies
export const ACTION_GET_SALE_POLICIES = 'salePolicies/getSalePolicies'
export const ACTION_GET_SALE_POLICY = 'salePolicies/getSalePolicy'

//Certicied
export const ACTION_GET_CERTICIED = 'certicied/getDetailCerticied'
export const ACTION_GET_INSPECTION = 'certicied/getInspection'

//Notification
export const ACTION_GET_NOTIFICATIONS = 'notifications/getNotifications'
export const ACTION_SET_TOKEN_NOTIFY = 'notifications/setTokenFirebase'
export const ACTION_SET_COUNT_NOTIFY = 'notifications/setCountNotification'
export const ACTION_MARK_READ_NOTIFICATION = 'notifications/markReadNotify'
export const ACTION_MARK_READ_ALL = 'notifications/markReadAllNotify'
export const ACTION_LAST_READ = 'notifications/saveLastReadNotification'
export const ACTION_GET_LAST_READ = 'notifications/getLastReadNotification'

//Tracking code
export const ACTION_SET_TRACKING_CODE = 'tracking/setTrackingCode'

// FOOTER
export const ACTION_SIGN_IN_EMAIL = 'authen/signInEmail'

//get infor ho tro Carpla
export const ACTION_GET_INFO_CARPLA = 'contact/getContactCarpla'


//sell car app
export const ACTION_GET_MANUFACTURES_APP = 'manufacturers-sellcar/getManufacturesApp'
export const ACTION_GET_BRAND_APP = 'brand-sellcar/getBrandApp'
export const ACTION_GET_MODEL_APP = 'model-sellcar/getModelApp'

