import config from '~/utils/config'
import queryString from 'query-string'

const state = () => ({
  manufacturers: [],
})


const mutations = {
  SET_MANUFACTURERS(sate, payload) {
    sate.manufacturers = payload
  }
}

const getters = {
  manufacturers(state) {
    return state.manufacturers
  }
}

const actions = {
  async getManufactures({commit}, query = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const {data: {data}} = await this.$axios({
          url: `${config.api.manufacturer}?${queryString.stringify(query)}`,
          method: 'get'
        })
        commit('SET_MANUFACTURERS', data)
        resolve(data)
      } catch (err) {
        resolve([])
      }
    })
  },
  async getManufacturerInfo({commit}, name) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.$axios.$get(`${config.api.manufacturer}/${name}`)
        resolve(res)
      } catch (err) {
        resolve([])
      }
    })
  },
  async getBrandInfo({commit}, name) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.$axios.$get(`${config.api.brand}/${name}`)
        resolve(res)
      } catch (err) {
        resolve([])
      }
    })
  }
}

export default {
  state, mutations, actions, getters
}
