import config from '~/utils/config'
import queryString from 'query-string'

const state = () => ({
  manufacturersApp: [],
})


const mutations = {
  SET_MANUFACTURERS_APP(sate, payload) {
    sate.manufacturersApp = payload
  }
}

const getters = {
  manufacturersApp(state) {
    return state.manufacturersApp
  }
}

const actions = {
  async getManufacturesApp({commit}, query = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const {data: {data}} = await this.$axios({
          url: `${config.api.manufacturerSellCar}?${queryString.stringify(query)}`,
          method: 'get'
        })
        commit('SET_MANUFACTURERS_APP', data)
        resolve(data)
      } catch (err) {
        resolve([])
      }
    })
  },
}

export default {
  state, mutations, actions, getters
}
