import md5 from 'md5'
import config from "./config";
import queryString from "query-string"

String.prototype.replaceAll = function (i, j) {
  const str = this;
  const re = new RegExp(i.replace(/\\/g, '\\\\'), 'gi')
  return str.replace(re, j)
};

export function formatCurrencySlider(number) {
  if (number) {
    const numString = number.toString()
    const length = numString.length
    let result = ''

    switch (length) {
      case 3: // trăm Triệu
        result = `${number} triệu`
        break
      case 4: // Tỷ
        if (numString[1] > 0) {
          result = `${numString[0]},${numString[1]} tỷ`
        } else {
          result = `${numString[0]} tỷ`
        }
        break
      default:
        break
    }

    return result
  }
  return '0'
}
function formatPath(path, imgOption) {
  const {w,h,q,fit,blur} = imgOption
  let pathFormat = `${path}?image_process=format,webp`
  if(w || h) {
    pathFormat += `/resize${fit ? ',m_fit' : ''}${w ? `,w_${w}` : ''}${h ? `,fh_${h}` : ''}${blur ? ',image/blur,r_10,s_10' : ''}${q ? `/quality,q_${q}` : ''}`
  }
  return pathFormat
}
export function urlImgHandle(path, imgOption = {}) {
  const {w, h, q, fit, blur} = imgOption
  let cdn = `${config.api.cdn}`

  // if (fit) {
  //   cdn = `${config.api.cdn}/fit-in`
  // }
  //
  // if (w || h) {
  //   if(!cdn.includes('/filters:format(webp)')) {
  //     // cdn += `/${w || ''}x${h || ''}/filters:format(webp)`
  //     cdn += `?image_process=resize${w ? `,w_${w}` : ''}${h ? `,h_${h}` : ''},format,webp`
  //   } else {
  //     // cdn += `/${w || ''}x${h || ''}`
  //     cdn += `?image_process=resize${w ? `,w_${w}` : ''}${h ? `,h_${h}` : ''}`
  //   }
  // } else {
  //   if(!cdn.includes('/filters:format(webp)')) {
  //     // cdn += '/filters:format(webp)'
  //     cdn += ',format,webp'
  //   }
  // }
  //
  // if (q) {
  //   // cdn += `/filters:quality(${q})`
  // }
  //
  // if (blur) {
  //   cdn = cdn.replace('/filters:format(webp)', '')
  //   if (q) {
  //     // cdn += `:blur(${blur}`
  //   } else {
  //     // cdn += `/filters:blur(${blur})`
  //   }
  // }

  if (!path) {
    path = 'car-default.jpg'
  }

  if (path && path.includes(config.api.cdn)) {
    const pathSplit = path?.split(config.api.cdn) || []
    const pathFormat = (pathSplit || [])[pathSplit.length - 1]
    const pathImg = formatPath(pathFormat, imgOption)
    return `${cdn}/${pathImg}`
  }

  if (path && (path.includes('http') || path.includes('https'))) {
    return formatPath(path, imgOption)
  }

  if (path && path[0] === '/') {
    return `${cdn}/${formatPath(path, imgOption)}`
  }
  return `${cdn}/${formatPath(path, imgOption)}`
}

export function getOldQuery() {
  let result = {}
  try {
    // bo query cu boi vi chi can lay query moi thoi, search cũ ở querybox có rồi
    // result = queryString.parse(window.location.search)
    if (process.client) {
      const pathname = window.location.pathname
      const arr = pathname.split("/").filter(i => i.trim())
      if (pathname.startsWith("/mua-xe/kieu-dang/") && arr[2]) {
        // màn kiểu dáng
        result.figure = arr[2]
        return result
      }
      if (pathname.startsWith("/mua-xe/chi-nhanh/") && arr[2]) {
        //man chi nhanh
        result.province = arr[2]
        return result
      }
      if (pathname.startsWith("/mua-xe/")) {
        if (arr[1]) {
          result.manufacturer = arr[1]
        }
        if (arr[2]) {
          result.brand = arr[2]
        }
        if (+arr[3]) {
          result.year = +arr[3]
        }
        return result
      }

      /*
      * mua-xe/:hangxe/:dongxe/:nam
      * mua-xe/kieu-dang/:figure
      * mua-xe/chi-nhanh/:province
      * */
    }
  } catch (e) {
    return result
  }
  return result
}

export function formatCurrency(number, notSuffix) {
  if (number) {
    const numString = number.toString()
    const length = numString.length

    let result = ''

    switch (length) {
      case 8: // chục Triệu
        result = `${numString.slice(0, 2)} Triệu`
        break
      case 9: // trăm Triệu
        result = `${numString.slice(0, 3)} Triệu`
        break
      case 10: // Tỷ
        if (+numString[1] > 0) {
          result = `${numString[0]} Tỷ ${+numString.slice(1, 4) > 0 ? numString.slice(1, 4) + ' Triệu' : ''}`
        } else {
          if (+numString[2] === 0) {
            result = `${numString[0]} Tỷ`
          } else {
            result = `${numString.slice(0, 1)} Tỷ ${numString.slice(2, 4)} triệu`
          }
        }
        break
      case 11: // chục Tỷ
        if (+numString[1] > 0) {
          result = `${numString.slice(0, 2)} Tỷ ${+numString.slice(2, 5) > 0 ? numString.slice(2, 5) + ' Triệu' : ''}`
        } else {
          result = `${numString.slice(0, 2)} Tỷ`
        }
        break
      default:
        result = number ? `${new Intl.NumberFormat('vi-VN').format(number)} ${notSuffix ? '' : 'đ'}` : ''
        break
    }

    return result
  }
  return '0'
}

export function formatStatistics(number) {
  if (number) {
    const numString = number.toString()
    const length = numString.length

    let result = ''

    switch (length) {
      case 7: // chục Triệu
        result = `${numString.slice(0, 1)} Triệu`
        break
      case 8: // chục Triệu
        result = `${numString.slice(0, 2)} Triệu`
        break
      case 9: // trăm Triệu
        result = `${numString.slice(0, 3)} Triệu`
        break
      case 10: // Tỷ
        if (numString[1] > 0) {
          result = `${numString[0]} Tỷ ${numString.slice(1, 4)} Triệu`
        } else {
          result = `${numString[0]} Tỷ`
        }
        break
      case 11: // chục Tỷ
        if (numString[1] > 0) {
          result = `${numString.slice(0, 2)} Tỷ ${numString.slice(1, 4)} Triệu`
        } else {
          result = `${numString.slice(0, 2)} Tỷ`
        }
        break
      default:
        result = number ? new Intl.NumberFormat('vi-VN').format(number) : ''
        break
    }

    return result
  }
  return '0'
}

export function checkSeats(seat) {
  // if (seat < 10) {
  //   return `0${seat}`
  // }
  return `${seat} chỗ`
}

const sortObjectByKey = (unordered) => {
  const ordered = {}
  Object.keys(unordered).sort().forEach(function (key) {
    if (unordered[key]) ordered[key] = unordered[key]
  })
  return ordered
}

export function getSignature(obj) {
  obj = sortObjectByKey(obj)
  const str = `${Object.values(obj).join('$')}$${process.env.NUXT_ENV_SECRET_SIGNATURE || '#123Ag'}`
  return md5(str)
}

export function formatPhoneNumber(phone) {
  return phone?.substring(0, 3) + '*****' + phone?.substring(8);
}

export function getVerticalScrollPercentage(elm) {
  let p = elm.parentNode
  return (elm.scrollTop || p.scrollTop) / (p.scrollHeight - p.clientHeight) * 100
}

export function formatStoM(t) {
  const m = Math.floor(t / 60)
  const s = t - 60 * m
  if (s === 0) return `0${m}:0${s}`
  return `0${m}:${s}`
}

export const strToSlug = (str) => {
  if (str) {
    str = str.replace(/A|Á|À|Ã|Ạ|Â|Ấ|Ầ|Ẫ|Ậ|Ă|Ắ|Ằ|Ẵ|Ặ/g, "A")
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a")
    str = str.replace(/E|É|È|Ẽ|Ẹ|Ê|Ế|Ề|Ễ|Ệ/, "E")
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e")
    str = str.replace(/I|Í|Ì|Ĩ|Ị/g, "I")
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i")
    str = str.replace(/O|Ó|Ò|Õ|Ọ|Ô|Ố|Ồ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ỡ|Ợ/g, "O")
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o")
    str = str.replace(/U|Ú|Ù|Ũ|Ụ|Ư|Ứ|Ừ|Ữ|Ự/g, "U")
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u")
    str = str.replace(/Y|Ý|Ỳ|Ỹ|Ỵ/g, "Y")
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y")
    str = str.replace(/Đ/g, "D")
    str = str.replace(/đ/g, "d")
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "") // Huyền sắc hỏi ngã nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, "") // Â, Ê, Ă, Ơ, Ư

    str = str.replace(/ /g, '-').replace(/[^\w-]+/g, '')

    return str?.toLowerCase()
  } else {
    return ''
  }
}

export function removeDuplicateObjects(array, property) {
  const uniqueIds = [];

  const unique = array.filter(element => {
    const isDuplicate = uniqueIds.includes(element[property]);

    if (!isDuplicate) {
      uniqueIds.push(element[property]);

      return true;
    }

    return false;
  });

  return unique;
}

export const slugIsProvince = (p) => {
  const slug = strToSlug(p)
  return slug === 'long-bien' || slug === 'da-nang' || slug === 'binh-duong' || slug === 'tan-phu';
}

export const slugToProvince = (slug) => {
  switch (slug) {
    case 'long-bien':
      return 'Long Biên'
    case  'da-nang':
      return 'Đà Nẵng'
    case 'binh-duong':
      return 'Bình Dương'
    case 'tan-phu':
      return 'Tân Phú'
    case 'can-tho':
      return 'Cần Thơ'
    case 'nghe-an':
      return 'Nghệ An'
    case 'pham-van-dong':
      return 'Phạm Văn Đồng'
    default:
      return slug
  }
}

export const genFilterTagsV2 = (item) => {
  if (item?.name) return item.name
  else if (item?.yearStart && item?.yearEnd && (item.yearStart === item.yearEnd)) return item.yearStart
  else if (item?.yearStart && item?.yearEnd) return item.yearStart + ' - ' + item.yearEnd
  else if (item?.odoStart >= 0 && item?.odoEnd) return item.odoStart + 'km - ' + item.odoEnd + 'km'
  else if (item?.priceStart >= 0 && item?.priceEnd) return formatCurrency(item.priceStart) + ' - ' + formatCurrency(item.priceEnd)
  else if (item?.gearType) return config.gearTpye[item.gearType]
  else if (item?.province) return item.province
  else return ''
}

export function cleanObj(obj) {
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      obj[key] = obj[key].filter(item => {
        const isEmpty = typeof item === "object" ? Object.keys(item).length === 0 : (item === null || item === undefined || item === '')
        return !isEmpty
      })
    }
  })
}

export function cleanStr(str) {
  Object.entries(str).forEach(([key, value]) => {
    if (typeof value === 'string' && value?.startsWith(',')) {
      str[key] = str[key].substring(1)
    }

    if (typeof value === "string" && value?.endsWith(',')) {
      str[key] = str[key].slice(0, -1)
    }

    const strCheck = typeof value === "string" ? value?.replaceAll(',', '') : ''
    if (!strCheck) {
      delete str[key]
    }
    if (!value) {
      delete str[key]
    }
  })
}

export function splitStringQuery(q, val) {
  if (q.includes(',' + val)) {
    return q.replace(',' + val, '')
  } else {
    return q.replace(val + ',', '')
  }
}

export function updateQueryFilter(item, query) {
  if (item?.manufacturerSlug) {
    if (query?.manufacturer && query?.manufacturer.includes(',')) {
      query.manufacturer = splitStringQuery(query.manufacturer, item.manufacturerSlug)
    } else {
      delete query.manufacturer
    }
    if (item?.brandSlug) {
      if (query?.brand && query?.brand.includes(',')) {
        query.brand = splitStringQuery(query.brand, item.brandSlug)
      } else {
        delete query.brand
      }
    }
  }
  if (item?.figureSlug) {
    if (query?.figure && query?.figure.includes(',')) {
      query.figure = splitStringQuery(query.figure, item.figureSlug)
    } else {
      delete query.figure
    }
  }
  if (item?.colorSlug) {
    if (query?.color && query?.color.includes(',')) {
      query.color = splitStringQuery(query.color, item.colorSlug)
    } else {
      delete query.color
    }
  }
  if (item?.gearType) {
    if (query?.gear && query?.gear.includes(',')) {
      query.gear = splitStringQuery(query.gear, item.gearType)
    } else {
      delete query.gear
    }
  }
  if (item?.province) {
    if (query.province.includes(',')) {
      query.province = splitStringQuery(query.province, item.province)
    } else {
      delete query.province
    }
  }
  if (item?.yearStart && item?.yearEnd) delete query.year
  if (item?.priceStart >= 0 && item?.priceEnd) delete query.price
  if (item?.odoStart >= 0 && item?.odoEnd) delete query.odo
  return query
}
