import config from "@/utils/config";
import queryString from 'query-string'
import {FAVORITE_CARS, USER_KEY} from "@/utils/constants";

const state = () => {
  return {
    cars: [],
    favoriteCars: []
  }
}

const actions = {
  async addRSAForm(vuex, {data, rsaId}) {
    // console.log('aaa')
    // console.log(data,rsaId)
    return new Promise((resolve, reject) => {
      this.$axios({
        url: `${config.api.rsa}/quotation/${rsaId}/confirm`,
        method: 'post',
        data
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve(error.response?.data);
        });
    })
  },
}
const mutations = {
}

const getters = {
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
