import config from '~/utils/config'
import queryString from 'query-string'
const state = () => ({
})


const mutations = {

}

const getters = {

}

const actions = {
  async getInstallmentPolicies({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {data} = await this.$axios({
          url: `${config.api.installmentPolicy}`,
          method: 'get',
        })
        resolve(data.data)
      } catch (err) {
        resolve([])
      }
    })
  },
}

export default {
  state, mutations, actions, getters
}
