import config from "@/utils/config";
import queryString from 'query-string'
const actions = {
  async getSalePolicies({commit}) {
    try {
      const res = await this.$axios.$get(`${config.api.salePolicies}`)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.resolve(e.response?.data)
    }
  },
  async getSalePolicy({commit}, slug) {
    try {
      const res = await this.$axios.$get(`${config.api.salePolicies}/slug/${slug}`)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.resolve(e.response?.data)
    }
  }
}

export default {actions}
