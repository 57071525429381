import config from '~/utils/config'
import queryString from 'query-string'

const state = () => ({
  modelApp: [],
})


const mutations = {
  SET_MODEL_APP(sate, payload) {
    sate.modelApp = payload
  }
}

const getters = {
  modelApp(state) {
    return state.modelApp
  }
}

const actions = {
  async getModelApp({commit}, query = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const {data: {data}} = await this.$axios({
          url: `${config.api.modelSellCar}?${queryString.stringify(query)}`,
          method: 'get'
        })
        commit('SET_MODEL_APP', data)
        resolve(data)
      } catch (err) {
        resolve([])
      }
    })
  },
}

export default {
  state, mutations, actions, getters
}
