import config from '~/utils/config'
import queryString from 'query-string'

const state = () => ({
  news: [],
  currentCategory: '',
  currentIDNew: ''
})


const mutations = {
  SET_NEWS(state, payload) {
    state.news = payload
  },
  SET_CURRENT_CATEGORY(state, payload) {
    state.currentCategory = payload
  },
  SET_CURRENT_ID_NEW(state, payload) {
    state.currentIDNew = payload
  },
}

const getters = {
  news(state) {
    return state.news
  },
  currentCategory(state) {
    return state.currentCategory
  },
  currentIDNew(state) {
    return state.currentIDNew
  }
}

const actions = {
  async getNews({ commit }, query) {
    return new Promise(async (resolve, reject) => {
      const res = await this.$axios.$get(`${config.api.news}?${queryString.stringify(query)}`)
      commit('SET_NEWS', res.data)
      resolve(res)
    })
  },
  async getNewItem({ commit }, slug) {
    return new Promise(async (resolve, reject) => {
      const res = await this.$axios.$get(`${config.api.news}/${slug}`)
      resolve(res)
    })
  },
  async getCategoryNews({ commit }, query) {
    return new Promise(async (resolve, reject) => {
      const { data } = await this.$axios.$get(`${config.api.categoryNews}?${queryString.stringify(query)}`)
      resolve(data)
    })
  },
  async getMostViewedNews({ commit }, query) {
    return new Promise(async (resolve, reject) => {
      const { data } = await this.$axios.$get(`${config.api.news}?${queryString.stringify(query)}`)
      resolve(data)
    })
  },
  async getHotNews({ commit }, query) {
    return new Promise(async (resolve, reject) => {
      const { data } = await this.$axios.$get(`${config.api.news}?isOutstanding=1`)
      resolve(data)
    })
  },
  async getAllNewsBuyCategories({commit}, {slug, page, perPage}) {
    return new Promise(async (resolve, reject) => {
      const res = await this.$axios.$get(`${config.api.news}?cateSlug=${slug}&perPage=${perPage}&page=${page}`)
      resolve(res)
    })
  },
  async searchNews({commit}, key) {
    return new Promise(async (resolve, reject) => {
      const {data} = await this.$axios.$get(`${config.api.news}?slug=${key}`)
      resolve(data || [])
    })
  },
  async getRelatedNews({commit}, query) {
    return new Promise(async (resolve, reject) => {
      const res = await this.$axios.$get(`${config.api.newsRandom}?${queryString.stringify(query)}`)
      resolve(res || [])
    })
  },
  setcurrentCategory({commit}, item) {
    commit('SET_CURRENT_CATEGORY', item)
  },
  currentIDNew({commit}, ID) {
    commit('SET_CURRENT_ID_NEW', ID)
  },

}

export default {
  state, mutations, actions, getters
}
