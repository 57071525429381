import config from "@/utils/config"
import queryString from 'query-string'

const actions = {
  async uploadFiles({commit}, params) {
    try {
      const {file} = params
      // console.log("config.api.uploadFile", config.api.uploadFile)
      const data = await this.$axios.$post(`${config.api.uploadFile}`, {type: params.type, name: file.name})
      const arrBuf = await file.arrayBuffer()
      const blob = new Blob([new Uint8Array(arrBuf)], {type: file.type})
      const res = await this.$axios({
        method: 'put',
        url: data.url,
        data: blob,
        headers: {
          'Content-Type': blob.type
        }
      })
      return Promise.resolve(data.path)
    } catch (e) {
      return Promise.resolve(e.response?.data)
    }
  },
}

export default {actions}
