import config from "@/utils/config"
import queryString from 'query-string'

const state = () => {
  return {
    popup: {}
  }
}

const actions = {
  async getPopup(vuex, params = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await this.$axios.$get(`${config.api.popup}?${queryString.stringify(params)}`)
        resolve(data)
      } catch (err) {
        resolve(err)
      }
    })
  }
}
const mutations = {
  SET_POPUP(state, payload) {
    state.popup = payload
  }
}

const getters = {
  popup(state) {
    return state.popup
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
