import FingerprintJS from "@fingerprintjs/fingerprintjs";
import {FAKE_DEVICEID, TOKEN_KEY, VISITOR_ID} from "@/utils/constants";
import appConfig from "@/utils/config";
import {getSignature} from "@/utils";
import moment from 'moment'

const state = () => ({
  app: {}
})


const mutations = {
  'RESET_STATE'(state, payload) {
    state = {}
  }
}

const actions = {
  async nuxtServerInit({dispatch, commit, state}, {req, res, app, beforeNuxtRender}) {
    // console.log('On Nuxt server 11.08.22b1')
    // console.log('REDIS_URL: ', process.env.REDIS_URL)
  },
  async nuxtClientInit({dispatch, commit, getters}) {
    if(getters['authen/intervalPing']) {
      dispatch('authen/ping')
    } else {
      clearInterval(getters['authen/intervalPing'])
    }

    if (!getters['authen/token'] || getters['authen/isGuest']) {
      const visitorId = this.$cookies.get(VISITOR_ID)
      if (!visitorId) {
        const fp = await FingerprintJS.load({})
        const result = await fp.get()
        console.log("VISITOR_ID",result)
        const visitorId = result.visitorId
        localStorage.setItem("visitorIdCarpla",visitorId)
        this.$cookies.set(VISITOR_ID, visitorId)
        dispatch('authen/checkGuest', true)
      } else {
        const isFakeId = !!this.$cookies.get(FAKE_DEVICEID)
        if (isFakeId) {
          //enterguest lai
          const data = {
            deviceType: appConfig.deviceType.WEB,
            deviceId: visitorId,
            versionCode: '1'
          }
          data.signature = getSignature(data)
          this.$axios.$post(appConfig.api.auth.enterGuest, data).then(res => {
            this.$cookies.set(TOKEN_KEY, res.token)
            dispatch('authen/checkGuest', true)
          })
          this.$cookies.remove(FAKE_DEVICEID)
        }
      }
    }

    // if (rudderanalytics && typeof rudderanalytics === 'object') {
      // rudderanalytics?.track(
      //   appConfig.eventType.Web_Opened,
      //   {
      //     timeIn: moment().unix(),
      //     source: ''
      //   },
      //   () => {
      //     // console.log('track event')
      //   }
      // )
    // }
  },
  resetState({commit}) {
    commit('RESET_STATE')
  }
}

const getters = {
  app(state) {
    return state.app
  }
}

export default {
  state, mutations, actions, getters
}
