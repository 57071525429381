import config from "@/utils/config";
import queryString from 'query-string'

const state = () => ({
  job: {}
})
const mutations = {
  SET_JOB(state, payload) {
    state.job = payload
  },
}

const getters = {
  job(state) {
    return state.job
  }
}

const actions = {
  async getRecruit({commit}, params) {
    try {
      const res = await this.$axios.$get(`${config.api.recruit}?${queryString.stringify(params)}`)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.resolve(e.response?.data)
    }
  },
  async getCateRecruit({commit}, params) {
    try {
      const res = await this.$axios.$get(`${config.api.cateRecruit}`)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.resolve(e.response?.data)
    }
  },
  async getRecruitDetail({commit}, slug) {
    try {
      const res = await this.$axios.$get(`${config.api.detailRecruit}/${slug}`)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.resolve(e.response?.data)
    }
  },
  async getRecruitRandom({commit}, id) {
    try {
      const res = await this.$axios.$get(`${config.api.recruitRandom}?id=${id}`)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.resolve(e.response?.data)
    }
  },
  setJob({commit}, job) {
    commit("SET_JOB", job)
  }
}

export default {actions, mutations, getters}
