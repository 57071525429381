import config from '~/utils/config'
import queryString from "query-string";

const state = () => ({
  rates: []
})


const mutations = {}

const getters = {}

const actions = {

  async getRates({commit}, {screen, page, perPage, sort}) {
    try {
      const url = screen ? `${config.api.rates}?fromScreen=${screen}&sort=desc` : `${config.api.rates}?${queryString.stringify({ page, perPage, sort })}`
      const res = await this.$axios.$get(url)
      return Promise.resolve(res)
    } catch (err) {
      return Promise.resolve(err.response?.data)
    }
  }
}

export default {
  state, mutations, actions, getters
}
