import moment from "moment/moment";

const domainBase = process.env.NUXT_ENV_API_BASE
const domainUpload = process.env.NUXT_ENV_API_UPLOAD
const domainAuth = process.env.NUXT_ENV_API_AUTH
const domainStorage = process.env.NUXT_ENV_API_STORAGE
const domainCDN = process.env.NUXT_ENV_API_CDN
const domainFile = process.env.NUXT_ENV_API_FILE
const domainFileTranscodeCDN = process.env.NUXT_ENV_VIDEO_TRANSCODE_CDN
const domainVETCKeyCloak = process.env.NUXT_ENV_VETC_KEY_CLOAK
const domainTransCode = process.env.NUXT_ENV_VIDEO_TRANSCODE || 'http://192.168.221.28:8050'

const currentYear = moment().get('year')

const config = {
  websiteUrl: process.env.NUXT_ENV_WEBSITE_URL || "https://carpla.vn",
  allowSEO: !!+process.env.ALLOW_SEO || !!+process.env.NUXT_ENV_ALLOW_SEO,
  secretKey: process.env.NUXT_ENV_CRYPTO_KEY || '8a20140f249eeb21befad80f74520243',
  api: {
    // base
    base: domainBase,
    buyCarDescription: `${domainBase}/description`,
    upload: domainUpload,
    storage: domainStorage,
    cdn: domainCDN,
    file: domainFile,
    transcodeCDN: domainFileTranscodeCDN,
    videoTranscode: domainTransCode,
    auth: {
      checkExistence: `${domainBase}/app/checkExistence`, login: `${domainAuth}/login`,
      logout: `${domainAuth}/logout`,
      register: `${domainBase}/app/register`,
      requestOTP: `${domainBase}/app/otp/request`,
      validateOTP: `${domainBase}/app/otp/validate`,
      resetPassword: `${domainBase}/app/resetPassword`,
      changePassword: `${domainBase}/app/changePassword`,
      loginSSO: `${domainBase}/app/singleSignOn`,
      enterGuest: `${domainAuth}/enterGuest`,
      user: `${domainAuth}/user`,
      refreshToken: `${domainAuth}/refreshToken`,
      ping: `${domainAuth}/ping`,
      oauth: `${domainBase}/oauth/authorize`,
      clientOauth: `${domainBase}/oauth/client`,
      vetcToken: `${domainAuth}/vetcToken`,
    },
    // feature
    popup: `${domainBase}/popup`,
    trending: `${domainBase}/trending`,
    searchHistory: `${domainBase}/searchHistory`,
    suggestion: `${domainBase}/suggestion`,
    transactionGuest: `${domainBase}/transactionGuest`,
    partner: `${domainBase}/partner`,
    car: `${domainBase}/car/car`,
    carAttributes: `${domainBase}/car/getAll`,
    favoriteCar: `${domainBase}/favoriteCar`,
    news: `${domainBase}/news`,
    newsRandom: `${domainBase}/newsRandom`,
    categoryNews: `${domainBase}/cateNews`,
    bannerListCars: `${domainBase}/app/banner`,
    banners: `${domainBase}/banner`,
    manufacturer: `${domainBase}/car/manufacturer`,
    brand: `${domainBase}/car/brand`,
    manufacturerSellCar: `${domainBase}/app/manufacturer`,
    brandSellCar: `${domainBase}/app/brand`,
    modelSellCar: `${domainBase}/app/model`,
    searchCar: `${domainBase}/search/carv2`,
    sellCar: `${domainBase}/sellCar`,
    sendRescue: `${domainBase}/app/sendRescue`,
    rsa: `${domainBase}/app/rsa`,
    figure: `${domainBase}/car/figure`,
    banks: `${domainBase}/app/banks`,
    quotations: `${domainBase}/app/quotations`,
    updateUserInfo: `${domainBase}/app/updateInfoUser`,
    updateFileUser: `${domainBase}/app/updateFileUser`,
    lead: `${domainBase}/lead`,
    interestedCar: `${domainBase}/interestedCar`,
    carPurchase: `${domainBase}/buyCar`,
    rates: `${domainBase}/rate`,
    statistic: `${domainBase}/statistic`,
    recruit: `${domainBase}/cateRecruitAggr`,
    cateRecruit: `${domainBase}/cateRecruit`,
    detailRecruit: `${domainBase}/recruit`,
    recruitRandom: `${domainBase}/recruitRandom`,
    seo: `${domainBase}/seo`,
    seoUrl: `${domainBase}/seoUrl`,
    redirect: `${domainBase}/redirect`,
    //faq
    faq: `${domainBase}/faq`,
    //upload file
    uploadFile: `${domainBase}/upload`,

    //application
    application: `${domainBase}/application`,
    salePolicies: `${domainBase}/policy`,
    detailCerticied: `${domainBase}/inspectionTable/config`,

    //inspection
    inspection: `${domainBase}/inspectionTableByCar`,
    view: `${domainBase}/view`,
    valuation: `${domainBase}/valuation`,
    testDrive: `${domainBase}/testDrive`,
    fcmToken: `${domainAuth}/user/fcmtoken`,

    //notifications
    notifications: `${domainBase}/notification/customer`,
    markReadNotify: `${domainBase}/customerNotify`,
    markReadAll: `${domainBase}/readAllNotify`,
    lastReadNotification: `${domainBase}/notification/lastRead`,
    installmentPolicy: `${domainBase}/installment`,
    campaign: `${domainBase}/campaign`,
  },
  httpCode: {
    OK: 200,
    CREATED: 201,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    TOKEN_EXPIRED: 409
  },
  intervalPing: 15 * 1000,
  sortType: {
    NEWEST: 'desc',
    OLDEST: 'asc',
    ASC_PRICE: 'ascPrice',
    DESC_PRICE: 'descPrice',
    ASC_ODO: 'ascOdo',
    DESC_ODO: 'descOdo',
    ASC_YEAR: 'ascYear',
    DESC_YEAR: 'descYear',
  },
  deviceType: {
    WEB: 3,
  },
  VETCAuthConfig: {
    applicationId: process.env.NUXT_ENV_VETC_APP_ID || "carpla",
    domain: domainVETCKeyCloak,
    realms: process.env.NUXT_ENV_VETC_REALMS || 'Etc'
  },
  leadType: {
    CAR_LIST: 1,
    TEST_DRIVE: 2,
    CALL_ME: 3,
    LOANS: 4,
    CAR_PURCHASE: 5,
    RSA: 6,
    COMING_SOON: 7,
    DEPOSITED: 8,
    SOLD: 9,
    ANOTHER: 10
  },
  carStatusMap: {
    COMING_SOON: 1,
    ON_SALE: 2,
    DEPOSITED: 3,
    SOLD: 4
  },
  carStatus: {
    1: 'Xe đang về',
    2: 'Đang bán',
    3: 'Đã nhận cọc',
    4: 'Xe đã bán'
  },
  carTag: {
    1: 'Nổi bật',
    2: 'Xem nhiều',
    3: 'Biển đẹp',
    4: 'Biển Hà Nội đẹp',
    5: 'Biển HCM đẹp',
  },
  carStatusColor: {
    1: '#1D2D5F',
    2: '#1D2D5F',
    3: '#1D2D5F',
    4: '#1D2D5F',
  },
  carTagColor: {
    1: '#d97706',
    2: '#0284c7',
    3: '#173559',
    4: '#4f46e5',
    5: '#4b5563',
  },
  screenType: {
    HOME_PAGE: 1,
    SELL_CAR: 2,
    CAR_PURCHASE: 3,
    SERVICE: 4,
    NEWS_HOME_PAGE: 5,
    INTRODUCE: 6,
    WHY_CHOOSE_CARPLA: 7,
    RECRUIT: 8,
    FAVORITE_CAR: 9,
    PROFILE: 10
  },
  screenBanner: {
    HOME_PAGE: 1,
    CAR_LIST: 2,
    CAR_DETAIL: 3,
    CAR_FOR_SALE: 4,
    NEWS_DETAIL: 5,
    RECRUIT: 6,
    FAQ: 7,
    LOGIN: 8,
    QUALITY_CERTIFICATION: 9,
    CAR_BRAND: 10,
    SALES_POLICY: 11
  },
  screenFeedback: {
    HOME_PAGE: 1,
    SELL_CAR: 2,
    CERTIFIED: 3,
    INSURANCE: 4
  },
  eventType: {
    Web_Opened: 'Web Opened',
    Web_Backgrounded: 'Web Backgrounded',
    CLICK_CAR: 'click_car',
    LIKE_CAR: 'like_car',
    USED_FILTER_CAR: 'used_filter_car',
    CLICK_HOTLINE: 'click_hotline',
    LOG_IN: 'log_in',
    SELL_CAR_FORM_INPUT: 'sell_car_form_input',
    OB_login_continue: 'OB_login_continue',
    OB_login_via_facebook: 'OB_login_via_facebook',
    OB_login_via_google: 'OB_login_via_google',
    OB_login_via_vetc: 'OB_login_via_vetc',
    OB_top_bar_home: 'OB_top_bar_home',
    OB_top_bar_hot_line: 'OB_top_bar_hot_line',
    OB_home_page_search_bar: 'OB_home_page_search_bar',
    OB_home_page_search_brand: 'OB_home_page_search_brand',
    OB_home_page_seach_figure: 'OB_home_page_seach_figure',
    OB_home_page_service_buy_car: 'OB_home_page_service_buy_car',
    OB_home_page_service_sell_car: 'OB_home_page_service_sell_car',
    OB_home_page_service_change_car: 'OB_home_page_service_change_car',
    OB_home_page_service_accessory: 'OB_home_page_service_accessory',
    OB_home_page_service_rsa_rescue: 'OB_home_page_service_rsa_rescue',
    OB_home_page_service_car_valuation: 'OB_home_page_service_car_valuation',
    OB_home_page_service_car_level: 'OB_home_page_service_car_level',
    OB_home_page_service_car_spa: 'OB_home_page_service_car_spa',
    OB_home_page_service_car_repair: 'OB_home_page_service_car_repair',
    OB_home_page_service_car_loan: 'OB_home_page_service_car_loan',
    OB_home_page_service_insurance: 'OB_home_page_service_insurance',
    OB_home_page_famous_brand_brand: 'OB_home_page_famous_brand_brand',
    OB_home_page_famous_brand_figure: 'OB_home_page_famous_brand_figure',
    OB_home_page_car_list_picture: 'OB_home_page_car_list_picture',
    OB_home_page_car_list_heart: 'OB_home_page_car_list_heart',
    OB_home_page_car_list_car_detail: 'OB_home_page_car_list_car_detail',
    OB_home_page_car_list_see_more: 'OB_home_page_car_list_see_more',
    OB_home_page_news_news: 'OB_home_page_news_news',
    OB_home_page_news_see_more: 'OB_home_page_news_see_more',
    OB_home_page_chat: 'OB_home_page_chat',
    OB_buy_car_search: 'OB_buy_car_search',
    OB_buy_car_car_list_compare: 'OB_buy_car_car_list_compare',
    OB_buy_car_car_list_sort: 'OB_buy_car_car_list_sort',
    OB_buy_car_car_list_picture: 'OB_buy_car_car_list_picture',
    OB_buy_car_car_list_heart: 'OB_buy_car_car_list_heart',
    OB_buy_car_car_info: 'OB_buy_car_car_info',
    OB_buy_car_advise_submit: 'OB_buy_car_advise_submit',
    OB_buy_car_chat: 'OB_buy_car_chat',
    OB_sell_car_submit: 'OB_sell_car_submit',
    OB_sell_car_chat: 'OB_sell_car_chat',
    OB_service_change_car_chat: 'OB_service_change_car_chat',
    OB_service_accessory_chat: 'OB_service_accessory_chat',
    OB_service_change_structure_car_chat: 'OB_service_change_structure_car_chat',
    OB_service_car_spa_chat: 'OB_service_car_spa_chat',
    OB_service_maintenance_chat: 'OB_service_maintenance_chat',
    OB_service_loan_chat: 'OB_service_loan_chat',
    OB_service_insurance_chat: 'OB_service_insurance_chat',
    OB_service_rescue_rsa_choose_pack: 'OB_service_rescue_rsa_choose_pack',
    OB_service_rescue_rsa_submit: 'OB_service_rescue_rsa_submit',
    OB_service_rescue_rsa_chat: 'OB_service_rescue_rsa_chat',
    OB_news_chat: 'OB_news_chat',
    OB_introduce_chat: 'OB_introduce_chat',
    OB_car_detail_picture: 'OB_car_detail_picture',
    OB_car_detail_information_share: 'OB_car_detail_information_share',
    OB_car_detail_information_heart: 'OB_car_detail_information_heart',
    OB_car_detail_information_see_all_information: 'OB_car_detail_information_see_all_information',
    OB_car_detail_information_register_test_drive: 'OB_car_detail_information_register_test_drive',
    OB_car_detail_information_call_users: 'OB_car_detail_information_call_users',
    OB_car_detail_quality_certification_see_all: 'OB_car_detail_quality_certification_see_all',
    OB_car_detail_pay_policy_advide_me: 'OB_car_detail_pay_policy_advide_me',
    OB_car_detail_click_other_car: 'OB_car_detail_click_other_car',
    OB_car_detail_related: 'OB_car_detail_related'
  },
  pageType: {
    JT_login: 'JT_login',
    JT_top_bar: 'JT_top_bar',
    JT_home_page: 'JT_home_page',
    JT_buy_car: 'JT_buy_car',
    JT_sell_car: 'JT_sell_car',
    JT_service_change_car: 'JT_service_change_car',
    JT_service_accessory: 'JT_service_accessory',
    JT_service_change_structure_car: 'JT_service_change_structure_car',
    JT_service_car_spa: 'JT_service_car_spa',
    JT_service_maintenance: 'JT_service_maintenance',
    JT_service_loan: 'JT_service_loan',
    JT_service_insurance: 'JT_service_insurance',
    JT_news: 'JT_news',
    OB_home_page_news_news: 'OB_home_page_news_news',
    OB_home_page_news_see_more: 'OB_home_page_news_see_more',
    JT_introduce: 'JT_introduce',
    JT_car_detail: 'JT_car_detail',
    JT_car_detail_confirm_register_test_drive: 'JT_car_detail_confirm_register_test_drive',
    JT_car_detail_confirm_call_users: 'JT_car_detail_confirm_call_users',
    JT_car_detail_share: 'JT_car_detail_share',
    JT_car_detail_confirm_pay_policy: 'JT_car_detail_confirm_pay_policy',
    JT_service_rescue_rsa: 'JT_service_rescue_rsa',
    Web_Opened: 'Web_Opened',
    Web_Backgrounded: 'Web_Backgrounded',
    Web_Installed: 'Web_Installed'
  },
  eventTypeTracking: {
    log_in_clicked: 'log_in_clicked',
    buying_car_list_viewed: 'buying_car_list_viewed',
    comparation_clicked: 'comparation_clicked',
    filter_clicked: 'filter_clicked',
    search_viewed: 'search_viewed',
    advanced_search_viewed: 'advanced_search_viewed',
    product_detail_viewed: 'product_detail_viewed',
    sellcar_form_viewed: 'sellcar_form_viewed',
    homepage_viewed: 'homepage_viewed',
    button_downloadapp_clicked: 'button_downloadapp_clicked',
    product_clicked: 'product_clicked',
    style_car_clicked: 'style_car_clicked',
    service_car_click: 'service_car_click',
    news_car_click: 'news_car_click',
    installment_form_filled: 'installment_form_filled',
    question_form_filled: 'question_form_filled',
    register_driver_form_filled: 'register_driver_form_filled',
    call_me_form_filled: 'call_me_form_filled',
    buy_car_form_filled: 'buy_car_form_filled',
    button_link_clicked: 'button_link_clicked',
    news_form_filled: 'news_form_filled',
    car_valuation_form_filled: 'car_valuation_form_filled',
    other_form_filled: 'other_form_filled',
    page_view: 'page_view'
  },
  newEventName:{
    login_clicked: 'login_clicked',
    scroll: 'scroll',
    contact_link_clicked: 'contact_link_clicked',
    outbound_link_clicked: 'outbound_link_clicked',
    video_watched: 'video_watched',
    form_started: 'form_started',
    form_summited: 'form_summited',
    searched: 'searched',
    car_compare: 'car_compare',
    wishlist_added: 'wishlist_added',
    product_shared: 'product_shared',
    subscriber_registered: 'subscriber_registered',
    related_product_impresion: 'related_product_impresion',
    related_product_click: 'related_product_click '
  },
  categoriesTopic: {
    1: 'Mua xe',
    2: 'Bán xe',
    3: 'FQA',
    4: 'Chung'

  },
  provinces: [
    {name: 'An Giang', value: 1},
    {name: 'Bà Rịa - Vũng Tàu', value: 2},
    {name: 'Bạc Liêu', value: 3},
    {name: 'Bắc Kạn', value: 4},
    {name: 'Bắc Giang', value: 5},
    {name: 'Bắc Ninh', value: 6},
    {name: 'Bến Tre', value: 7},
    {name: 'Bình Dương', value: 8},
    {name: 'Bình Định', value: 9},
    {name: 'Bình Phước', value: 10},
    {name: 'Bình Thuận', value: 11},
    {name: 'Cà Mau', value: 12},
    {name: 'Cao Bằng', value: 13},
    {name: 'TP. Cần Thơ', value: 14},
    {name: 'TP. Đà Nẵng', value: 15},
    {name: 'Đắk Lắk', value: 16},
    {name: 'Đắk Nông', value: 17},
    {name: 'Điện Biên', value: 18},
    {name: 'Đồng Nai', value: 19},
    {name: 'Đồng Tháp', value: 20},
    {name: 'Gia Lai', value: 21},
    {name: 'Hà Giang', value: 22},
    {name: 'Hà Nam', value: 23},
    {name: 'TP. Hà Nội', value: 24},
    {name: 'Hà Tĩnh', value: 25},
    {name: 'Hải Dương', value: 26},
    {name: 'TP. Hải Phòng', value: 27},
    {name: 'Hòa Bình', value: 28},
    {name: 'TP. Hồ Chí Minh', value: 29},
    {name: 'Hậu Giang', value: 30},
    {name: 'Hưng Yên', value: 31},
    {name: 'Khánh Hòa', value: 32},
    {name: 'Kiên Giang', value: 33},
    {name: 'Kon Tum', value: 34},
    {name: 'Lai Châu', value: 35},
    {name: 'Lào Cai', value: 36},
    {name: 'Lạng Sơn', value: 37},
    {name: 'Lâm Đồng', value: 38},
    {name: 'Long An', value: 39},
    {name: 'Nam Định', value: 40},
    {name: 'Nghệ An', value: 41},
    {name: 'Ninh Bình', value: 42},
    {name: 'Ninh Thuận', value: 43},
    {name: 'Phú Thọ', value: 44},
    {name: 'Phú Yên', value: 45},
    {name: 'Quảng Bình', value: 46},
    {name: 'Quảng Nam', value: 47},
    {name: 'Quảng Ngãi', value: 48},
    {name: 'Quảng Ninh', value: 49},
    {name: 'Quảng Trị', value: 50},
    {name: 'Sóc Trăng', value: 51},
    {name: 'Sơn La', value: 52},
    {name: 'Tây Ninh', value: 53},
    {name: 'Thái Bình', value: 54},
    {name: 'Thái Nguyên', value: 55},
    {name: 'Thanh Hóa', value: 56},
    {name: 'Thừa Thiên – Huế', value: 57},
    {name: 'Tiền Giang', value: 58},
    {name: 'Trà Vinh', value: 59},
    {name: 'Tuyên Quang', value: 60},
    {name: 'Vĩnh Long', value: 61},
    {name: 'Vĩnh Phúc', value: 62},
    {name: 'Yên Bái', value: 63},
  ],
  plateNumTypeConfig: {
    1: 'Biển Hà Nội',
    2: 'Biển Hồ Chí Minh',
    3: 'Biển tỉnh'
  },
  provinceConfig: {
    'Tất cả': {
      name: 'Tất cả',
      value: 'Tất cả',
    },
    'Long Biên': {
      name: 'Long Biên',
      value: 'Long Biên',
      slug: 'long-bien'
    },
    'Đà Nẵng': {
      name: 'Đà Nẵng',
      value: 'Đà Nẵng',
      slug: 'da-nang'
    },
    'Bình Dương': {
      name: 'Bình Dương',
      value: 'Bình Dương',
      slug: 'binh-duong'
    },
    'Tân Phú': {
      name: 'Tân Phú',
      value: 'Tân Phú',
      slug: 'tan-phu'
    },
    'Cần Thơ': {
      name: 'Cần Thơ',
      value: 'Cần Thơ',
      slug: 'can-tho'
    },
    'Phạm Văn Đồng': {
      name: 'Phạm Văn Đồng',
      value: 'Phạm Văn Đồng',
      slug: 'pham-van-dong'
    },
    'Nghệ An': {
      name: 'Nghệ An',
      value: 'Nghệ An',
      slug: 'nghe-an'
    }
  },
  inspectionTypeConfig: {
    'success': 1,
    'error': 2,
    'na': 3
  },
  recruitConfig: {
    PART_TIME: 1,
    FULL_TIME: 2,
    COLLABORATOR: 3,
    INTERN: 4
  },
  featureType: {
    exterior: 1,
    interior: 2,
    other: 3
  },
  gearTpye: {
    1: 'Số tự động',
    2: 'Số sàn'
  },
  ageList: [
    {
      name: '18 - 24 tuổi',
      value: 21
    },
    {
      name: '25 - 34 tuổi',
      value: 30
    },
    {
      name: '35 - 44 tuổi',
      value: 40
    },
    {
      name: '45 - 54 tuổi',
      value: 50
    },
    {
      name: 'Trên 55 tuổi',
      value: 60
    }
  ],
  odoOptions: [
    { value: [0, 30000], name: 'Dưới 30.000' },
    { value: [0, 50000], name: 'Dưới 50.000' },
    { value: [0, 70000], name: 'Dưới 70.000' },
    { value: [0, 100000], name: 'Dưới 100.000' },
  ],
  priceOptions: [
    { value: [300, 500], name: 'Dưới 500tr' },
    { value: [500, 700], name: '500tr - 700tr' },
    { value: [700, 1000], name: '700tr - 1 tỷ' },
    { value: [1000, 5000], name: 'Trên 1 tỷ' },
  ],
  yearOptions: [
    { value: [2013, 2015], name: '2013 - 2015' },
    { value: [2016, 2018], name: '2016 - 2018' },
    { value: [2019, 2021], name: '2019 - 2021' },
    { value: [currentYear - 1, currentYear], name: `${currentYear - 1} - ${currentYear}` },
  ],
  allowDCMA:!!+process.env.NUXT_ENV_ENABLE_DCMA
}
console.log(config.websiteUrl, "allowSeo is:", config.allowSEO)
export default config
