
const state = () => {
  return {
    tracking_code: {}
  }
}

const actions = {
  async setTrackingCode({ commit }, payload) {
    commit("SET_TRACKING_CODE", payload)
  },
}
const mutations = {
  SET_TRACKING_CODE(state, payload) {
    state.tracking_code = payload
  }
}

const getters = {
  tracking_code(state) {
    return state.tracking_code
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
