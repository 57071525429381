import config from "@/utils/config"
import queryString from 'query-string'

const state = () => {
  return {
    seoList: []
  }
}

const actions = {
  async getSeoList(vuex, params = {}) {
    return new Promise((resolve, reject) => {
      this.$axios.$get(`${config.api.seo}?${queryString.stringify(params)}`)
        .then((response) => {
          resolve(response)
          vuex.commit('SET_SEO_DATA', response.data)
        })
        .catch((error) => {
          resolve(error.response?.data)
        })
    })
  },
  async getSeoByTargetId(vuex, params = {}) {
    return new Promise((resolve, reject) => {
      this.$axios.$get(`${config.api.seo}?${queryString.stringify(params)}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          resolve(error.response?.data)
        })
    })
  },
  async getSeoByUrl(vuex, path) {
    return new Promise((resolve, reject) => {
      this.$axios.$get(`${config.api.seoUrl}/${path}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          resolve(error.response?.data)
        })
    })
  },
}
const mutations = {
  SET_SEO_DATA(state, payload) {
    state.seoList = payload
  }
}

const getters = {
  seoList(state) {
    return state.seoList
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
