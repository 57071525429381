import config from "@/utils/config";

const state = () => ({
  count: 0,
})


const mutations = {
  SET_COUNT_NOTIFICATIONS(state, payload) {
    state.count = payload
  },
}

const getters = {
  count(state) {
    return state.count
  }
}

const actions = {
  async getNotifications({commit}, {page}) {
    try {
      const res = await this.$axios.$get(`${config.api.notifications}?perPage=10&page=${page}`)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.resolve(e.response?.data)
    }
  },
  async setTokenFirebase({commit}, data) {
    try {
      const res = await this.$axios.put(`${config.api.fcmToken}`, data)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.resolve(e.response?.data)
    }
  },
  setCountNotification({commit}, count) {
    commit('SET_COUNT_NOTIFICATIONS', count)
  },
  async markReadNotify({commit}, data) {
    try {
      const res = await this.$axios.$put(`${config.api.markReadNotify}`, data)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.resolve(e.response?.data)
    }
  },
  async markReadAllNotify({commit}) {
    try {
      const res = await this.$axios.$put(`${config.api.markReadAll}`)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.resolve(e.response?.data)
    }
  },
  async saveLastReadNotification({commit}, data) {
    try {
      const res = await this.$axios.$put(`${config.api.lastReadNotification}`, data)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.resolve(e.response?.data)
    }
  },
  async getLastReadNotification({commit}) {
    try {
      const res = await this.$axios.$get(`${config.api.lastReadNotification}`)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.resolve(e.response?.data)
    }
  }
}

export default {actions, state, mutations, getters}
