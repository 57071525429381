import config from '~/utils/config'
import queryString from 'query-string'

const state = () => ({
  partners: [],
})


const mutations = {
  SET_PARTNERS(state, payload) {
    state.partners = payload
  },
}

const getters = {
  partners(state) {
    return state.partners
  }
}

const actions = {
  async getPartners({ commit }) {
    const { data } = await this.$axios.$get(`${config.api.partner}`)
    commit('SET_PARTNERS', data)
    return Promise.resolve(data)
  }
}

export default {
  state, mutations, actions, getters
}
