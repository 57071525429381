import config from '~/utils/config'

const state = () => ({
})


const mutations = {}

const getters = {}

const actions = {

  async getStatistics({commit}, {screen}) {
    try {
      const res = await this.$axios.$get(`${config.api.statistic}?fromScreen=${screen}&sort=desc&perPage=3`)
      return Promise.resolve(res)
    } catch (err) {
      return Promise.resolve(err.response?.data)
    }
  }
}

export default {
  state, mutations, actions, getters
}
